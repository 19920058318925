import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';

function createStorage<Value>(): SyncStorage<Value | undefined> {
  return {
    getItem: (key: string, initialValue?: Value) => {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? (JSON.parse(storedValue) as Value) : initialValue;
    },
    setItem: (key: string, newValue?: Value) => {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    },
    removeItem: (key: string) => {
      sessionStorage.removeItem(key);
    },
  };
}

export default createStorage
