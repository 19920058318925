import { ShowRowContent } from '../types/api/show-data';
import { atomWithStorage } from 'jotai/utils';

let storedRecommendedShows

if (typeof window !== "undefined") {
  storedRecommendedShows = JSON.parse(window.localStorage.getItem('recommendedShows')!) as ShowRowContent[]
} else {
  storedRecommendedShows = undefined
}

export const recommendedShowsAtom = atomWithStorage<ShowRowContent[] | undefined>('recommendedShows', storedRecommendedShows);
