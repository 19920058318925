import { ContinueWatchingContent } from '@/lib/types/api/home-data';
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils';
import CompanionState from '@/lib/types/atoms/companionState';

import createStorage from '@/lib/helpers/createStorage';

const storage = createStorage<ContinueWatchingContent[]>()

let storedContinueWatching

if (typeof window !== "undefined") {
  storedContinueWatching = JSON.parse(window.sessionStorage.getItem('continueWatching')!) as ContinueWatchingContent[]
} else {
  storedContinueWatching = undefined
}

export const continueWatchingAtom = atomWithStorage<ContinueWatchingContent[] | undefined>('continueWatching', storedContinueWatching, storage);
export const continueWatchingStatusAtom = atom<CompanionState>(CompanionState.NotLoaded)
