import ShowRow from "@/components/ShowRow/ShowRow";
import { ShowRowContent } from "@/lib/types/api/show-data";
interface FeaturedShowsRowProps {
  shows: ShowRowContent[];
}
const FeaturedShowsRow = (props: FeaturedShowsRowProps) => {
  const {
    shows
  } = props;
  return <ShowRow shows={shows} title={'Featured Shows'} data-sentry-element="ShowRow" data-sentry-component="FeaturedShowsRow" data-sentry-source-file="FeaturedShowsRow.tsx" />;
};
export default FeaturedShowsRow;