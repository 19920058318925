import { atom } from 'jotai';
import { ShowRowContent } from '../types/api/show-data';
import { atomWithStorage } from 'jotai/utils';
import CompanionState from '@/lib/types/atoms/companionState';
import createStorage from '@/lib/helpers/createStorage';

const storage = createStorage<ShowRowContent[]>()

let storedMyListShows

if (typeof window !== "undefined") {
  storedMyListShows = JSON.parse(window.localStorage.getItem('myListShows')!) as ShowRowContent[]
} else {
  storedMyListShows = undefined
}

export const myListShowsAtom = atomWithStorage<ShowRowContent[] | undefined>('myListShows', storedMyListShows, storage);
export const myListShowsStatus = atom<CompanionState>(CompanionState.NotLoaded)
